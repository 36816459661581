<template>
  <mf-loading v-if="loading" :booleanLoader="loading" loaderType="dialog" color="#999999" />
  <v-container v-else key="content" class="pa-0" fluid>
    <slot />
  </v-container>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>
